import Vue from 'vue';
import axios from 'axios';
import * as ACTIONS from '../actions';

const vue = new Vue();

const state = {

};

const getters = {

};

const actions = {

    [ACTIONS.CREATE_ARTICLE]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .put(getters.api.articles.articles, data.article)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.UPDATE_ARTICLE]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.articles.articles + '/' + data.article.id, data.article)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.GET_ARTICLE]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.articles.articles + '/' + data.id)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.GET_ARTICLE_CATEGORIES]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.articles.categories)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.GET_ARTICLE_BY_CATEGORY]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.articles.categories + '/' + data.category_id)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.UPLOAD_ARTICLE_IMAGE]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.articles.uploadImage, data, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(res => {
                    let link = res.data.link
                    resolve(link);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
};

const mutations = {

};

export default {
    state,
    getters,
    actions,
    mutations,
}
