import Vue from 'vue';
import axios from 'axios';
import * as ACTIONS from '../actions';

const vue = new Vue();

const state = {
};

const getters = {

};

const actions = {
    [ACTIONS.REPORT_PROGRESS_GET]: ({getters}, groupBy) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.reports.progress, {params: {groupBy: groupBy}})
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
};

const mutations = {
};

export default {
    state,
    getters,
    actions,
    mutations,
}