<template>

    <v-app class="blue-grey lighten-5" id="app">

        <v-content class="pt-0">
            <TopNavbar v-if="this.$user.get().role !== 'guest'"/>
            <div class="wrapper">
                <v-layout class="layout-inner" justify-center>
                    <v-flex md12>
                        <router-view></router-view>
                    </v-flex>
                </v-layout>
            </div>
        </v-content>

        <MyManual/>

        <notifications group="all" position="bottom right"></notifications>

    </v-app>

</template>

<script>

    export default {
        name: 'App',
        components: {
            'TopNavbar': () => import('./components/pages/header/TopNavbar.vue'),
            'MyManual': () => import('./components/pages/MyManual'),
        },
        data() {
            return {}
        },
        methods: {},
        mounted: function () {
            const crmUpdate = this.$cookie.get('crmUpdate');
            if (crmUpdate) {
                this.$notifySuccess({text: 'CRM updated!'});
                this.$cookie.delete('crmUpdate');
            }
        },
    }
</script>

<style lang="sass">
    @import 'styles/normalize'
    @import 'styles/mixins'
    @import 'styles/custom'
</style>
