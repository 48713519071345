import Vue from 'vue';
import axios from 'axios';
import * as ACTIONS from '../actions';

const vue = new Vue();

const state = {

};

const getters = {

};

const actions = {
    [ACTIONS.GET_CONTRACT_STATUSES]: ({getters}) => {
        return new Promise((resolve) => {
            return axios
              .get(getters.api.contracts.statuses)
              .then(res => {
                  resolve(res.data.data);
              })
              .catch(err => {
                  vue.$catchProceed(err);
              });
        });
    },

    [ACTIONS.GET_CONTRACTS_BY_DEAL]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
              .get(getters.api.contracts.contractsByDeal + '/' + params.deal_id)
              .then(res => {
                  resolve(res.data.data);
              })
              .catch(err => {
                  vue.$catchProceed(err);
              });
        });
    },

    [ACTIONS.DOWNLOAD_CONTRACTS_FILE]: ({getters}, data) => {
        return new Promise(() => {
            return axios
              .get(getters.api.contracts.download + '/' + data.file_id)
              .then(res => {
                  window.location.href = res.data;
              })
              .catch(err => {
                  vue.$catchProceed(err);
              });
        });
    },

    [ACTIONS.DELETE_CONTRACTS_FILE]: ({getters}, file_id) => {
        return new Promise((resolve) => {
            axios
              .delete(getters.api.contracts.delete + '/' + file_id)
              .then(res => {
                  vue.$notifySuccess({text: 'File deleted'});
                  resolve(res);
              })
              .catch(err => {
                  vue.$catchProceed(err);
              });
        });
    },

    [ACTIONS.DELETE_CONTRACT]: ({getters}, contract_id) => {
        return new Promise((resolve) => {
            axios
              .delete(getters.api.contracts.delete_contract + '/' + contract_id)
              .then(res => {
                  vue.$notifySuccess({text: 'Contract deleted'});
                  resolve(res);
              })
              .catch(err => {
                  vue.$catchProceed(err);
              });
        });
    },

    [ACTIONS.SEND_CONTRACT]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
              .post(getters.api.contracts.contracts + '/' + data.deal_id, data.file, {
                  headers: {
                      'Content-Type': 'multipart/form-data'
                  }
              })
              .then(res => {
                  resolve(res.data.data);
                  vue.$notifySuccess({text: 'Contract created'});
              })
              .catch(err => {
                  vue.$catchProceed(err);
              });
        });
    },

    [ACTIONS.EDIT_CONTRACT]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
              .post(getters.api.contracts.update + '/' + data.contract_id, data)
              .then(res => {
                  resolve(res.data.data);
                  vue.$notifySuccess({text: 'Contract edited'});
              })
              .catch(err => {
                  vue.$catchProceed(err);
                  resolve();
              });
        });
    },

    [ACTIONS.CREATE_CONTRACT]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
              .post(getters.api.contracts.contracts + '/' + data.deal_id, data)
              .then(res => {
                  resolve(res.data.data);
                  vue.$notifySuccess({text: 'Contract created'});
              })
              .catch(err => {
                  vue.$catchProceed(err);
                  resolve();
              });
        });
    },

    [ACTIONS.CONTRACT_CHANGE_STATUS]: ({getters}, data) => {
        return new Promise((resolve, reject) => {
            return axios
              .put(getters.api.contracts.changeStatus + '/' + data.contract_id, data)
              .then(res => {
                  resolve(res.data.data);
                  vue.$notifySuccess({text: 'Success'});
              })
              .catch(err => {
                  vue.$catchProceed(err);
                  reject(err.response.data.data);
              });
        });
    },

    [ACTIONS.ATTACH_CONTRACT_FILE]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
              .post(getters.api.contracts.files + '/' + data.contract_id, data.file, {
                  headers: {
                      'Content-Type': 'multipart/form-data'
                  }
              })
              .then(res => {
                  resolve(res.data.data);
                  vue.$notifySuccess({text: 'File is loaded'});
              })
              .catch(err => {
                  vue.$catchProceed(err);
              });
        });
    },

    [ACTIONS.ATTACH_RESULT_FOR_CONTRACT]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
              .post(getters.api.contracts.results + '/' + data.contract_id, data.file, {
                  headers: {
                      'Content-Type': 'multipart/form-data'
                  }
              })
              .then(res => {
                  resolve(res.data.data);
                  vue.$notifySuccess({text: 'Contract edited'});
              })
              .catch(err => {
                  vue.$catchProceed(err);
              });
        });
    },

    [ACTIONS.DELETE_RESULT_FILE]: ({getters}, params) => {
        return new Promise((resolve) => {
            axios
              .delete(getters.api.contracts.results + '/' + params.file_id)
              .then(res => {
                  vue.$notifySuccess({text: 'Deleted file'});
                  resolve(res);
              })
              .catch(err => {
                  vue.$catchProceed(err);
              });
        });
    },

    [ACTIONS.SAVE_OFFLINE_FILE]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
              .post(getters.api.contracts.offline + '/' + data.stat_id, data.file, {
                  headers: {
                      'Content-Type': 'multipart/form-data'
                  }
              })
              .then(res => {
                  vue.$notifySuccess({text: 'File uploaded'});
                  resolve(res.data);
              })
              .catch(err => {
                  vue.$catchProceed(err);
              });
        });
    },

    [ACTIONS.OFFLINE_FILE_DELETE]: ({getters}, file_id) => {
        return new Promise((resolve) => {
            axios
              .delete(getters.api.contracts.offline + '/' + file_id)
              .then(res => {
                  vue.$notifySuccess({text: 'Deleted file'});
                  resolve(res);
              })
              .catch(err => {
                  vue.$catchProceed(err);
              });
        });
    },

    [ACTIONS.DELETE_PAYMENT_CONTRACT]: ({getters}, params) => {
        return new Promise((resolve) => {
            axios
              .delete(getters.api.payments.paymentContract + '/' + params.payment_id)
              .then(res => {
                  vue.$notifySuccess({text: 'Deleted contract'});
                  resolve(res);
              })
              .catch(err => {
                  vue.$catchProceed(err);
              });
        });
    },

    [ACTIONS.DELETE_CONTRACT_FORMAT]: ({getters}, format_id) => {
        return new Promise((resolve) => {
            return axios
              .delete(getters.api.contracts.delete_format + '/' + format_id)
              .then(res => {
                  resolve(res.data.data);
              })
              .catch(err => {
                  vue.$catchProceed(err);
              });
        });
    },

    [ACTIONS.ATTACH_LEAD_FOR_CONTRACT]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
              .put(getters.api.contracts.contracts + '/' + data.contract_id + '/lead/attach', data)
              .then(res => {
                  resolve(res.data.data);
              })
              .catch(err => {
                  vue.$catchProceed(err);
              });
        });
    },

    [ACTIONS.DETACHED_LEAD_FROM_CONTRACT]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
              .put(getters.api.contracts.contracts + '/' + data.contract_id + '/lead/detach')
              .then(res => {
                  resolve(res.data.data);
              })
              .catch(err => {
                  vue.$catchProceed(err);
              });
        });
    },

    [ACTIONS.GET_DEAL_FORMATS_BY_ID]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
              .get(getters.api.contracts.formats + '/' + params.deal_id)
              .then(res => {
                  resolve(res.data.data);
              })
              .catch(err => {
                  vue.$catchProceed(err);
              });
        });
    },

    [ACTIONS.GET_CONTRACTS]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
              .get(getters.api.contracts.contracts, {params: params})
              .then(res => {
                  resolve(res.data);

              })
              .catch(err => {
                  vue.$catchProceed(err);
              });
        });
    },

    [ACTIONS.CREATE_FIN_DATA]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
              .post(getters.api.contracts.financialData + '/' + data.contract_id, data)
              .then(res => {
                  resolve(res.data.data);
              })
              .catch(err => {
                  vue.$catchProceed(err);
              });
        });
    },

    [ACTIONS.COPY_FIN_DATA]: ({getters}, contract_id) => {
        return new Promise((resolve) => {
            return axios
              .get(getters.api.contracts.financialData + '/' + contract_id)
              .then(res => {
                  resolve(res.data.data);
              })
              .catch(err => {
                  vue.$catchProceed(err);
              });
        });
    },

    [ACTIONS.BUTTONS_FOR_ROLE]: ({getters}) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.contracts.roleAllowedActions)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.RESERVE_CONTRACTS]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.contracts.contract_lock, data)
                .then(res => {
                    vue.$notifySuccess({text: res.data.data.message});
                    resolve(res.data);
                })
                .catch(error => {
                    vue.$catchProceed(error);
                });
        });
    },

};

const mutations = {

};

export default {
    state,
    getters,
    actions,
    mutations,
}
