import Vue from 'vue';
import Notifications from 'vue-notification';

Vue.use(Notifications);

const vue = new Vue();

const Notify = {
    install(Vue, options) {
        Vue.prototype.$notifySuccess = ({title = 'Success', text} = {}) =>
            vue.$notify({
                group: 'all',
                type: 'success',
                title: title,
                text: text,
                duration: 5000
            });

        Vue.prototype.$notifyInfo = ({title = 'Info', text} = {}) =>
            vue.$notify({
                group: 'all',
                type: 'info',
                title: title,
                text: text,
                duration: 5000
            });

        Vue.prototype.$notifyWarning = ({title = 'Warning', text} = {}) =>
            vue.$notify({
                group: 'all',
                type: 'warning',
                title: title,
                text: text,
                duration: 5000
            });

        Vue.prototype.$notifyError = ({title = 'Error', text} = {}) =>
            vue.$notify({
                group: 'all',
                type: 'error',
                title: title,
                text: text,
                duration: 5000
            });

    }
};

Vue.use(Notify);