import Vue from 'vue'
import * as EVENTS from '@/store/events'
import Pusher from 'pusher-js'

const vue = new Vue()

const channels = [
  {
    // private message
    channel: 'private-notifications.',
    event: 'chat_message',
    type: 'private',
    action: (message) => {
      vue.$eventEmit(EVENTS.GET_NEW_MESSAGES_TOOLBAR)
      vue.$eventEmit(EVENTS.GET_NEW_MESSAGES)
      vue.$eventEmit(EVENTS.GET_NEW_MESSAGE_BY_CHAT_ID, message.chat_id)
    }
  },

  {
    // private notifications
    channel: 'private-notifications.',
    event: 'notification',
    type: 'private',
    action: () => {
      vue.$eventEmit(EVENTS.GET_NOTIFICATION)
    }
  },

  {
    // сообщения каждому юзеру
    channel: 'notifications',
    event: 'notification', // для публичных каналов надо в начале ставить точку
    type: 'public',
    action: () => {
      vue.$eventEmit(EVENTS.GET_NOTIFICATION)
    }
  },
]

const PusherPlugin = {
  install (Vue, options) {

    Vue.prototype.$subscribe = (user_id) => {

      const pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
        cluster: 'eu',
        encrypted: true,
        authEndpoint: process.env.VUE_APP_API_URL + 'broadcasting/auth',
        auth: {
          headers: {
            'token': localStorage.getItem('user-token'),
          }
        },
      })

      // отписываемся от всего
      pusher.disconnect()
      pusher.connect()

      channels.forEach((listener) => {
        // если авторизован - подписываемся
        if (user_id) {

          const suffix = listener.type === 'private' ? user_id : ''
          const channel = pusher.subscribe(listener.channel + suffix)

          channel.bind(listener.event, (message) => {
            vue.$notifySuccess({ title: message.title, text: message.message })
            listener.action(message)
          })
        }
      })
    }
  }
}

Vue.use(PusherPlugin)
