export default {
    success: '#81C784',
    info: '#64B5F6',
    warning: '#FFB74D',
    error: '#E57373',

    success_lite: '#94e497',
    info_lite: '#b5dbf4',
    warning_lite: '#ffd67c',
    error_lite: '#ffafa9',

    white: '#FFFFFF',
}