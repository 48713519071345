import Vue from 'vue';
import axios from 'axios';
import * as ACTIONS from '../actions';

const vue = new Vue();

const state = {
    addedLeadContacts: [],
    deleteContact: false,
    validationLeadContact: false,
    contactCounter: 0
};

const getters = {
    addedLeadContacts: state => state.addedLeadContacts,
    deleteContact: state => state.deleteContact,
    validationLeadContact: state => state.validationLeadContact,
    contactCounter: state => state.contactCounter,
};

const actions = {
    [ACTIONS.LEAD_CONTACT_DELETE_FLAG]: ({commit}, data) => {
        return new Promise((resolve) => {
            commit('deleteContact', data);
            resolve();
        });
    },
    [ACTIONS.LEAD_CONTACT_TO_STORE_DATA_ADD]: ({commit}, filter) => {
        commit('addLeadContact', filter);
    },
    [ACTIONS.LEAD_CONTACT_STORE_DATA_CLEAR]: ({commit}) => {
        commit('clearLeadContact');
    },
    [ACTIONS.LEAD_CONTACT_STORE_DATA_DELETE]: ({commit}, index) => {
        commit('deleteLeadContact', index);
    },
    [ACTIONS.LEAD_CONTACT_STORE_DATA_EDIT]: ({commit}, data) => {
        commit('editLeadContact', data);
    },
    [ACTIONS.LEAD_CARD_GET_CONTACT]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.contacts.getContactById, {params: params})
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.LEAD_CONTACTS_CREATE]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.contacts.createContact, {leadId: params.leadId, contacts: params.contacts})
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.LEAD_CONTACT_INCREMENT_ACTION]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.contacts.incrementContactAction, {contactId: params.contactId, contactName: params.contactName})
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.LEAD_CONTACTS_EDIT]: ({getters}, params) => {
        return new Promise((resolve) => {
            axios
                .post(getters.api.contacts.editContact, params)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.LEAD_CONTACT_DELETE]: ({getters}, contactId) => {
        return new Promise((resolve) => {
            axios
                .post(getters.api.contacts.deleteContact, {contactId: contactId})
                .then(res => {
                    vue.$notifySuccess({text: 'Contact deleted'});
                    resolve(res);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.LEAD_CONTACT_VALIDATION]: ({commit}, data) => {
        return new Promise((resolve) => {
            commit('validationLeadContact', data);
            resolve();
        });
    },

    [ACTIONS.LEAD_CARD_CONTACTS_COUNTER]: ({commit}) => {
        return new Promise((resolve) => {
            commit('leadContactCounter');
            resolve();
        });
    },
    [ACTIONS.CLEAR_CONTACTS_COUNTER]: ({commit}) => {
        return new Promise((resolve) => {
            commit('clearLeadCounter');
            resolve();
        });
    },

};

const mutations = {
    deleteContact(state, payload) {
        state.deleteContact = payload;
    },
    clearLeadContact(state) {
        state.addedLeadContacts = [];
    },
    addLeadContact(state, payload) {
        state.addedLeadContacts[payload.index] = payload.new_tab;
    },
    editLeadContact(state, payload) {
        state.addedLeadContacts[payload.index] = payload.new_value;
    },
    deleteLeadContact(state, payload) {
        state.addedLeadContacts[payload] = undefined;
    },
    validationLeadContact(state, payload) {
        state.validationLeadContact = payload;
    },
    leadContactCounter(state) {
        state.contactCounter++;
    },
    clearLeadCounter(state) {
        state.contactCounter = 0;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
}
