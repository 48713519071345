import Vue from 'vue';
import axios from 'axios';

const vue = new Vue();

import * as ACTIONS from '../actions';

const state = {
    geoGroupList: [],
};

const getters = {
    geoGroupList: state => state.geoGroupList,
};

const actions = {
    [ACTIONS.GEO_GROUP_LIST]: ({commit, getters}) => {
        return new Promise((resolve, reject) => {
            return axios
                .get(getters.api.geoGroups.list)
                .then(res => {
                    commit('setGeoGroupList', res.data.data);
                    resolve();
                })
                .catch(err => {
                    vue.$catchProceed(err);
                    reject(err.response);
                });
        });
    },
    [ACTIONS.GEO_GROUP_GET]: ({getters}, geoGroupId) => {
        return new Promise((resolve, reject) => {
            return axios
                .get(getters.api.geoGroups.findById, {params: {geoGroupId: geoGroupId}})
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                    reject(err.response);
                });
        });
    },
    [ACTIONS.GEO_GROUP_EDIT]: ({getters}, data) => {
        return new Promise((resolve, reject) => {
            return axios
                .post(getters.api.geoGroups.editGroup, data)
                .then(res => {
                    vue.$notifySuccess({text: 'Group edited'});
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                    reject(err.response);
                });
        });
    },
    [ACTIONS.GEO_GROUP_CREATE]: ({getters}, data) => {
        return new Promise((resolve, reject) => {
            return axios
                .post(getters.api.geoGroups.createGroup, data)
                .then(res => {
                    vue.$notifySuccess({text: 'Group created'});
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                    reject(err.response);
                });
        });
    },
    [ACTIONS.GEO_GROUP_NAME_UNIQUE_CHECK]: ({getters}, params) => {

        return new Promise((resolve, reject) => {
            return axios
                .get(getters.api.geoGroups.checkName, {params: params})
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                    reject(err.response);

                });
        });
    },

};

const mutations = {
    setGeoGroupList(state, payload) {
        state.geoGroupList = payload;
    },

};

export default {
    state,
    getters,
    actions,
    mutations,
}