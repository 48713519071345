import Vue from 'vue';
import axios from 'axios';
import * as ACTIONS from '../actions';

const vue = new Vue();

const state = {
    tagList: [],
};

const getters = {
    tagList: state => state.tagList,
};

const actions = {
    [ACTIONS.ATTACH_TAG_FOR_PAYMENT]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.payments.tag + '/' + params.payment_id, params)
                .then(res => {
                    vue.$notifySuccess({text: 'Tag attached'});
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.TAG_LIST]: ({commit, getters}) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.tags.tags)
                .then(res => {
                    commit('setTagList', res.data.data);
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.TAG_GET]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.tags.tags + '/' + params.tag_id)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.TAG_CREATE]: ({getters}, data) => {
        return new Promise((resolve, reject) => {
            return axios
                .put(getters.api.tags.tags, data)
                .then(res => {
                    vue.$notifySuccess({text: 'Tag created'});
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                    reject(err.response);
                });
        });
    },
    [ACTIONS.TAG_EDIT]: ({getters}, params) => {
        return new Promise((resolve, reject) => {
            return axios
                .post(getters.api.tags.tags + '/' + params.tag_id, params)
                .then(res => {
                    vue.$notifySuccess({text: 'Tag edited'});
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                    reject(err.response);
                });
        });
    },
    [ACTIONS.TAG_NAME_UNIQUE_CHECK]: ({getters}, params) => {
        return new Promise((resolve, reject) => {
            return axios
                .get(getters.api.tags.checkName, {params: params})
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                    reject(err.response);
                });
        });
    },
    [ACTIONS.DELETE_ATTACHED_TAG]: ({getters}, params) => {
        return new Promise((resolve) => {
            axios
                .delete(getters.api.payments.tag + '/' + params.payment_id, {params: params})
                .then(res => {
                    vue.$notifySuccess({text: 'Tag deleted'});
                    resolve(res);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
};

const mutations = {
    setTagList(state, payload) {
        state.tagList = payload;
    },

};

export default {
    state,
    getters,
    actions,
    mutations,
}