import Vue from 'vue';
import axios from 'axios';
import * as ACTIONS from '../actions';

const vue = new Vue();

const state = {

};

const getters = {

};

const actions = {

    [ACTIONS.CREATE_NEW_PLACEMENT]: ({getters}, data) => {
        return new Promise((resolve, reject) => {
            return axios
                .post(getters.api.placements.placement_confirmation + '/' + data.deal_id, data)
                .then(res => {
                    resolve(res.data.data);
                    vue.$notifySuccess({text: 'Success'});
                })
                .catch(err => {
                    vue.$catchProceed(err);
                    reject(err.response);
                });
        });
    },

    [ACTIONS.CREATE_PLACEMENT_PLACE]: ({getters}, data) => {
        return new Promise((resolve, reject) => {
            return axios
                .post(getters.api.placements.placement_place + '/' + data.placement_id, data.file, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(res => {
                    resolve(res.data.data);
                    vue.$notifySuccess({text: 'Success'});
                })
                .catch(err => {
                    vue.$catchProceed(err);
                    reject(err.response);
                });
        });
    },
    [ACTIONS.CREATE_PLACEMENT_PLACE_SMM]: ({getters}, data) => {
        return new Promise((resolve, reject) => {
            return axios
                .post(getters.api.placements.placement_place + '/' + data.placement_id, data)
                .then(res => {
                    resolve(res.data.data);
                    vue.$notifySuccess({text: 'Success'});
                })
                .catch(err => {
                    vue.$catchProceed(err);
                    reject(err.response);
                });
        });
    },

    [ACTIONS.MASS_ADD_PLACEMENT_PLACE]: ({getters}, data) => {
        return new Promise((resolve, reject) => {
            return axios
                .post(getters.api.placements.mass_add_place + '/' + data.placement_id, data)
                .then(res => {
                    resolve(res.data.data);
                    vue.$notifySuccess({text: 'Success'});
                })
                .catch(err => {
                    vue.$catchProceed(err);
                    reject(err.response);
                });
        });
    },

    [ACTIONS.UPDATE_PLACEMENT_PLACE]: ({getters}, data) => {
        return new Promise((resolve, reject) => {
            return axios
                .put(getters.api.placements.placement_place + '/' + data.id + '/' + data.placement_id, data)
                .then(res => {
                    resolve(res.data.data);
                    vue.$notifySuccess({text: 'Updated place'});
                })
                .catch(err => {
                    vue.$catchProceed(err);
                    reject(err.response);
                });
        });
    },

    [ACTIONS.GET_DEAL_PLACEMENTS]: ({getters}, deal_id) => {
        return new Promise((resolve, reject) => {
            return axios
                .get(getters.api.placements.placement_confirmation + '/' + deal_id)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                    reject(err);
                });
        });
    },

    [ACTIONS.GET_PLACEMENTS_AVAILABLE_PAYMENTS]: ({getters}, deal_id) => {
        return new Promise((resolve, reject) => {
            return axios
                .get(getters.api.placements.payments + '/' + deal_id)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                    reject(err);
                });
        });
    },

    [ACTIONS.ATTACH_PLACE_SCREENSHOT]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.placements.upload_screenshot + '/' + data.place_id, data.file, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(res => {
                    resolve(res.data.data);
                    vue.$notifySuccess({text: 'File is loaded'});
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.DELETE_PLACEMENT_PLACE]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .delete(getters.api.placements.placement_place + '/' + data.id + '/' + data.placement_id)
                .then(res => {
                    resolve(res.data.data);
                    vue.$notifySuccess({text: 'Deleted'});
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.DELETE_PLACE_SCREENSHOT]: ({getters}, data) => {
        return new Promise((resolve) => {
            axios
                .delete(getters.api.placements.delete_file + '/' + data.file_id + '/' + data.placement_id)
                .then(res => {
                    vue.$notifySuccess({text: 'File deleted'});
                    resolve(res);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.UPDATE_PLACEMENT]: ({getters}, data) => {
        return new Promise((resolve, reject) => {
            return axios
                .put(getters.api.placements.placement_confirmation + '/' + data.id, data)
                .then(res => {
                    resolve(res.data.data);
                    vue.$notifySuccess({text: 'Updated placement'});
                })
                .catch(err => {
                    vue.$catchProceed(err);
                    reject(err.response);
                });
        });
    },

    [ACTIONS.DELETE_PLACEMENT]: ({getters}, placement_id) => {
        return new Promise((resolve) => {
            axios
                .delete(getters.api.placements.placement_confirmation + '/' + placement_id)
                .then(res => {
                    vue.$notifySuccess({text: 'Placement deleted'});
                    resolve(res);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.DISTRIBUTE_PLACEMENT_COST]: ({getters}, data) => {
        return new Promise((resolve, reject) => {
            return axios
                .put(getters.api.placements.autodistribution + '/' + data.id)
                .then(res => {
                    resolve(res.data.data);
                    vue.$notifySuccess({text: 'Success'});
                })
                .catch(err => {
                    vue.$catchProceed(err);
                    reject(err.response);
                });
        });
    },

    [ACTIONS.DOWNLOAD_PLACE_FILE]: ({getters}, data) => {
        return new Promise((resolve, reject) => {
            return axios
                .get(getters.api.placements.downloal_file + '/' + data.file_id + '/' + data.placement_id)
                .then(res => {
                    resolve(res.data);
                    window.location.href = res.data;
                })
                .catch(err => {
                    vue.$catchProceed(err);
                    reject(err);
                });
        });
    },

    [ACTIONS.GET_LANDING_TYPES]: ({getters}) => {
        return new Promise((resolve, reject) => {
            return axios
                .get(getters.api.placements.landing_types)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                    reject(err);
                });
        });
    },

};

const mutations = {};

export default {
    state,
    getters,
    actions,
    mutations,
}
