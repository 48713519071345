const admins = () => {
    return [
        {
            role: "manager",
            access: false,
            redirect: "MainPage"
        },
        {
            role: "control",
            access: false,
            redirect: "MainPage"
        },
        {
            role: "spamer",
            access: false,
            redirect: "MainPage"
        },
        {
            role: "searcher",
            access: false,
            redirect: "MainPage"
        }
    ];
};

// доступ к списку лидов
const leadList = () => {
    return [
        {
            role: "control",
            access: false,
            redirect: "MainPage"
        },
    ];
};

// доступ к списку лидов
const adminOnly = () => {
    return [
        {
            role: "control",
            access: false,
            redirect: "MainPage"
        },
        {
            role: "manager",
            access: false,
            redirect: "MainPage"
        },
        {
            role: "spamer",
            access: false,
            redirect: "MainPage"
        },
        {
            role: "searcher",
            access: false,
            redirect: "MainPage"
        },
        {
            role: "buying_control",
            access: false,
            redirect: "MainPage"
        },
        {
            role: "auditor",
            access: false,
            redirect: "MainPage"
        },
        {
            role: "fincontrol",
            access: false,
            redirect: "MainPage"
        },
        {
            role: "teamlead",
            access: false,
            redirect: "MainPage"
        },
        {
            role: "chief",
            access: false,
            redirect: "MainPage"
        },
    ];
};

// указываем роли, кому доступ разрешен
// admins - админам и чифам и т.д.
export default {
    admins,
    leadList,
    adminOnly
}