import Vue from 'vue';
import axios from 'axios';
import * as ACTIONS from '../actions';
const vue = new Vue();

const actions = {
    [ACTIONS.GET_AGENTS_LIST]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.agent.agent, {params: params})
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.EDIT_AGENT]: ({getters}, data) => {
        return new Promise((resolve, reject) => {
            return axios
                .post(getters.api.agent.agent + '/' + data.id, data)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                    reject(err.response);
                });
        });
    },
    [ACTIONS.GET_AGENT_CONDITIONS_LIST]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.agent.condition, {params: params})
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.CREATE_AGENT_CONDITION]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .put(getters.api.agent.condition, data)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.EDIT_AGENT_CONDITION]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.agent.condition + '/' + data.id, data)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.REJECT_AGENT]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.agent.rejected + '/' + data.agent_id, data)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.DOWNLOAD_AGENTS_FILE]: ({getters}, params) => {
        return axios
            .post(getters.api.agent.file, params)
            .then(res => {
                window.location.href = res.data.data.link;
            })
            .catch(err => {
                vue.$catchProceed(err);
            });
    },

    [ACTIONS.DOWNLOAD_CSV_FILE]: ({getters}, params) => {
        return axios
            .get(getters.api.agent.export, {params: params})
            .then(res => {
                window.location.href = res.data.data.link;
            })
            .catch(err => {
                vue.$catchProceed(err);
            });
    },

    [ACTIONS.CREATE_NEW_AGENT]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .put(getters.api.agent.agent, data)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.DASHBOARD_TOP_GEO]: ({getters}) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.agent.dashboard.top_geo)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        })
    },

    [ACTIONS.DASHBOARD_AGENTS_COUNT]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.agent.dashboard.count, {params: params})
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        })
    },

    [ACTIONS.DASHBOARD_AGENTS_STATUSES]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.agent.dashboard.status, {params: params})
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        })
    },

    [ACTIONS.DASHBOARD_AGENTS_GEOGRAPHY]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.agent.dashboard.geo, {params: params})
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        })
    },

    [ACTIONS.MOBICASH_UPLOAD_TO_CSV_TOP_GEO]: ({getters}, params) => {
        return axios
            .get(getters.api.agent.export_csv.top_geo, {params: params})
            .then(res => {
                window.location.href = res.data.data.link;
            })
            .catch(err => {
                vue.$catchProceed(err);
            });
    },

    [ACTIONS.MOBICASH_UPLOAD_TO_CSV_STATUSES]: ({getters}, params) => {
        return axios
            .get(getters.api.agent.export_csv.status, {params: params})
            .then(res => {
                window.location.href = res.data.data.link;
            })
            .catch(err => {
                vue.$catchProceed(err);
            });
    },

    [ACTIONS.MOBICASH_UPLOAD_TO_CSV_GEO]: ({getters}, params) => {
        return axios
            .get(getters.api.agent.export_csv.geo, {params: params})
            .then(res => {
                window.location.href = res.data.data.link;
            })
            .catch(err => {
                vue.$catchProceed(err);
            });
    },

    [ACTIONS.MOBICASH_UPLOAD_TO_CSV_COUNT]: ({getters}, params) => {
        return axios
            .get(getters.api.agent.export_csv.count, {params: params})
            .then(res => {
                window.location.href = res.data.data.link;
            })
            .catch(err => {
                vue.$catchProceed(err);
            });
    },
};

export default {
    actions,
}
