import Vue from 'vue';

const EventBus = new Vue();

const Bus = {
    install(Vue, options) {
        Vue.prototype.$eventOn = (name, payload) => {
            EventBus.$on(name, payload);
        };

        Vue.prototype.$eventEmit = (name, payload) => {
            EventBus.$emit(name, payload);
        };

        Vue.prototype.$eventOff = (name, payload) => {
            EventBus.$off(name, payload);
        };
    }
};

Vue.use(Bus);