import Vue from 'vue';
import axios from 'axios';
import * as ACTIONS from '../actions';
const vue = new Vue();

const state = {};
const getters = {};

const actions = {

    [ACTIONS.DASHBOARD_HISTORY_GET]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.dashboards.getHistoryData, {params: params})
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.DASHBOARD_CURRENT_GET]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.dashboards.getCurrentData, {params: params})
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.DASHBOARD_CHART]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.dashboards.getCharts, {params: params})
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.DASHBOARD_STATUS_STATS]: ({getters}) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.dashboards.getStatsByStatuses)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.GET_DEALS_REPORT]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.deals.report, {params: params})
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.GET_DEALS_REPORT_BY_MANAGER]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.deals.reportByDeals, {params})
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.GET_CHART_DATA]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.deals.reportByDealsCharts, {params})
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.DOWNLOAD_REPORT_BY_MANAGER]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.deals.reportByDealsXls, {params: data})
                .then(res => {
                    resolve(res.data.data);
                    // vue.$notifyInfo({text: 'The link will be sent to you in notifications'});
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        })
    },

    [ACTIONS.GET_COST_ITEMS]: ({getters}) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.filters.getCostItems)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.GET_USER_DATA]: ({getters}) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.dashboards.userDashboard)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

};

const mutations = {};

export default {
    state,
    getters,
    actions,
    mutations,
}
