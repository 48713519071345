import Vue from 'vue';
const vue = new Vue();

const Error = {
    install(Vue, options) {
        Vue.prototype.$catchProceed = (error) => {
            if (error.response.data.hasOwnProperty('data')) {
                if (error.response.data.data.hasOwnProperty('message')) {
                    if (error.response.data.data.message !== 'Wrong number of segments') {
                        vue.$notifyError({text: error.response.data.data.message});
                    }
                }
            } else {
                for (var key in error.response.data) {
                    if (error.response.data.hasOwnProperty(key)) {
                        if (error.response.data[key][0] !== 'Wrong number of segments') {
                            vue.$notifyError({text: error.response.data[key][0]});
                        }
                    }
                }
            }
        }
    }
};

Vue.use(Error);