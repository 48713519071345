import Vue from 'vue';
import axios from 'axios';
import * as ACTIONS from '../actions';

const vue = new Vue();

const state = {

};

const getters = {

};

const actions = {

    [ACTIONS.ADD_NEW_MIRROR]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .put(getters.api.leads.mirrors + '/' + params.lead_id, params)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.DELETE_MIRROR]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .delete(getters.api.leads.main + '/' + data.mirror_id)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.CHOOSE_MAIN_MIRROR]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.leads.main + '/' + data.mirror_id, data)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.GET_MIRRORS_LIST]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.leads.mirrors_list + '/' + params.lead_id)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
};

const mutations = {

};

export default {
    state,
    getters,
    actions,
    mutations,
}