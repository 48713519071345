import Vue from 'vue';
import axios from 'axios';
import * as ACTIONS from '../actions';

const vue = new Vue();

const state = {

};

const getters = {

};

const actions = {

    [ACTIONS.GET_PROMOCODES]: ({getters}, params) => {
        return new Promise((resolve, reject) => {
            return axios
                .get(getters.api.promocodes.promocodes + '/' + params.deal_id)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                    reject(err);
                });
        });
    },

    [ACTIONS.CREATE_PRESET]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .put(getters.api.aparser.preset, data)
                .then(res => {
                    resolve(res.data.data);
                    vue.$notifySuccess({text: 'Success'});
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.ADD_NEW_PROMOCODE]: ({getters}, data) => {
        return new Promise((resolve, reject) => {
            return axios
                .post(getters.api.promocodes.promocodes + '/' + data.deal_id, data)
                .then(res => {
                    resolve(res.data.data);
                    vue.$notifySuccess({text: 'Success'});
                })
                .catch(err => {
                    vue.$catchProceed(err);
                    reject(err.response);
                });
        });
    },

    [ACTIONS.EDIT_PROMOCODE]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .put(getters.api.promocodes.promocodes + '/' + data.promocode_id, data)
                .then(res => {
                    resolve(res.data.data);
                    vue.$notifySuccess({text: 'Success'});
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.DELETE_PROMOCODE]: ({getters}, id) => {
        return new Promise((resolve) => {
            return axios
                .delete(getters.api.promocodes.promocodes + '/' + id)
                .then(res => {
                    resolve(res.data.data);
                    vue.$notifySuccess({text: 'Deleted'});
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

};

const mutations = {};

export default {
    state,
    getters,
    actions,
    mutations,
}
