import Vue from 'vue';
import axios from 'axios';
import * as ACTIONS from '../actions';
const vue = new Vue();

const state = {};

const getters = {};

const actions = {

  [ACTIONS.GET_DOCUMENTATION_CONTRACT_REPORT]: ({getters}) => {
    return new Promise((resolve) => {
      return axios
        .get(getters.api.documentation.contracts)
        .then(res => {
          resolve(res.data.data);
        })
        .catch(err => {
          vue.$catchProceed(err);
        });
    });
  },

  [ACTIONS.GET_DOCUMENTATION_PAYMENTS_REPORT_GENERAL]: ({getters}, params) => {
    return new Promise((resolve) => {
      return axios
        .get(getters.api.documentation.dashboards, {params: params})
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          vue.$catchProceed(err);
        });
    });
  },

  [ACTIONS.GET_DOCUMENTATION_PAYMENTS_REPORT_AVERAGE]: ({getters}, params) => {
    return new Promise((resolve) => {
      return axios
        .get(getters.api.documentation.payments_average, {params: params})
        .then(res => {
          resolve(res.data.data);
        })
        .catch(err => {
          vue.$catchProceed(err);
        });
    });
  },

};

const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations,
}
