import Vue from 'vue';

const vue = new Vue();

const state = {

};

const getters = {

};

const actions = {

};

const mutations = {
};
export default {
    state,
    getters,
    actions,
    mutations,
}