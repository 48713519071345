import Vue from 'vue';
import axios from 'axios';
import * as ACTIONS from '../actions';

const vue = new Vue();

const state = {

};

const getters = {

};

const actions = {

    [ACTIONS.CREATE_NEW_ANALYTICS]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.payments.payments + '/' + data.payment_id + '/' + 'places', data)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
};

const mutations = {

};

export default {
    state,
    getters,
    actions,
    mutations,
}
