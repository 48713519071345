import axios from 'axios';
import * as ACTIONS from '../actions';
import Vue from 'vue';

const vue = new Vue();

const state = {

};

const getters = {

};

const actions = {
    [ACTIONS.ADD_PARTNER_TO_EXCEPTION]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .put(getters.api.partners.tickets_analytic_exception, data)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.DELETE_PARTNER_FROM_EXCEPTION]: ({getters}, params) => {
        return new Promise((resolve) => {
            axios
                .delete(getters.api.partners.tickets_analytic_exception, {params: params})
                .then(res => {
                    vue.$notifySuccess({text: 'Deleted'});
                    resolve(res);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.GET_EXCEPTION_PARTNERS]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.partners.tickets_analytic_exception, {params: params})
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.SEND_PARTNER_TICKET]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.partners.tickets_analytic + '/' + data.ticket_id, data.file, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(res => {
                    resolve(res.data.data);
                    vue.$notifySuccess({text: 'Sent'});
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.GET_PARTNER_TICKETS]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.partners.tickets_analytic, {params: params})
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.GET_PARTNER_CARD_TICKETS]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.partners.tickets_analytic_by_partner + '/' + params.partner_id, {params: params})
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
};

const mutations = {


};

export default {
    state,
    getters,
    actions,
    mutations,
}