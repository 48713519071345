import Vue from 'vue';
import axios from 'axios';

import * as ACTIONS from '../actions';

const vue = new Vue();

const state = {};

const getters = {};

const actions = {

    [ACTIONS.GET_AUDITOR_LIST]: ({getters}) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.settings.auditorList)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.GET_AUDITOR_RESPONSIBLE]: ({getters}) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.settings.auditorResponsibleId)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.UPDATE_AUDITOR_LIST]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .put(getters.api.settings.updateAuditorResponsible, data)
                .then(res => {
                    vue.$notifySuccess({text: 'Update'});
                    resolve(res.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.GET_FILE_FORMATS_LIST]: ({getters}) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.settings.getAllChatsExtensions)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.GET_SELECTED_FILE_FORMATS]: ({getters}) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.settings.getChatsExtensionsSettings)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.UPDATE_FILE_FORMATS_LIST]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .put(getters.api.settings.updateChatsExtensionSettings, data)
                .then(res => {
                    vue.$notifySuccess({text: 'Updated'});
                    resolve(res.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

};

const mutations = {};

export default {
    state,
    getters,
    actions,
    mutations,
}
