import Vue from 'vue';
import axios from 'axios';
import * as ACTIONS from '../actions';

const vue = new Vue();

const state = {

};

const getters = {

};

const actions = {

    [ACTIONS.GET_RETENTION_ISSUES]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.retention.brandbandits, {params: params})
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.GET_RETENTION_STATUS_LIST]: ({getters}) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.retention.statusList)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.GET_RETENTION_STATUS_FLOW]: ({getters}) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.retention.statusFlow)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.GET_REASON_LIST]: ({getters}) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.retention.reasonList)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.GET_RETENTION_COUNTRY_LIST]: ({getters}) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.retention.countryList)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.UPDATE_RETENTION_STATUS]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.retention.brandbandits + '/' + params.brandbandit_id, params.file, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.SEARCH_RETENTION_SITE]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.retention.searchSite, {params: params})
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.RETENTION_MASS_ACTION]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.retention.massAction, data)
                .then(res => {
                    resolve(res.data.data);

                    vue.$notifySuccess({text: 'Status updated'});
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.RETENTION_UPLOAD_FILE]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.deals.client_stat, data.file, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(res => {
                    vue.$notifySuccess({text: 'File uploaded'});
                    resolve(res.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.GET_RETENTION_PROJECTS]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.seo.projects)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

};

const mutations = {

};

export default {
    state,
    getters,
    actions,
    mutations,
}
