import axios from 'axios';
import * as ACTIONS from '../actions';
import Vue from 'vue';

const vue = new Vue();

const state = {};

const getters = {};

const actions = {

    [ACTIONS.DELETE_REQUEST_FILE]: ({getters}, image_id) => {
        return new Promise((resolve) => {
            return axios
                .delete(getters.api.request.delete_image + '/' + image_id)
                .then(res => {
                    resolve(res.data.data);
                    vue.$notifySuccess({text: 'Deleted'});
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.MAKE_LEAD_REQUEST]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.request.request + '/' + data.lead_id, data.file, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(res => {
                    resolve(res.data.data);
                    vue.$notifySuccess({text: 'Success'});
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.GET_MY_LEAD_REQUEST]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.request.request, {params:params})
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.GET_REQUEST_LIST_FOR_CONTROL]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.request.transfer, {params: params})
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.GET_COLD_LIST_FOR_CONTROL]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.request.cold, {params: params})
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.TRANSFER_COLD_LEADS]: ({getters}, data) => {
        return new Promise((resolve, reject) => {
            return axios
                .put(getters.api.request.cold_transfer, data)
                .then(res => {
                    resolve(res.data.data);
                    vue.$notifySuccess({text: 'Success'});
                })
                .catch(err => {
                    vue.$catchProceed(err);
                    reject(err.response.data.data);
                });
        });
    },

    [ACTIONS.REQUEST_CHANGE_STATUS]: ({getters}, data) => {
        return new Promise((resolve, reject) => {
            return axios
                .put(getters.api.request.transfer + '/' + data.lead_request_id, data)
                .then(res => {
                    resolve(res.data.data);
                    vue.$notifySuccess({text: 'Success'});
                })
                .catch(err => {
                    vue.$catchProceed(err);
                    reject(err.response.data.data);
                });
        });
    },

    [ACTIONS.DELETE_MY_LEAD_REQUEST]: ({getters}, lead_id) => {
        return new Promise((resolve) => {
            axios
                .delete(getters.api.request.request + '/' + lead_id)
                .then(res => {
                    vue.$notifySuccess({text: 'Request deleted'});
                    resolve(res);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

};

const mutations = {};

export default {
    state,
    getters,
    actions,
    mutations
}
