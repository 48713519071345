export const number_format = value => {
    let val = (value / 1).toFixed(0).replace('.', ',');
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

export const clickable = text => {
    const URLMatcher = /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/igm
    return text.replace(URLMatcher, match => '<a href="${match}" target="_blank">${match}</a>');
};

export const cut = (name, length) => length >= name.length ? name : name.substring(0, length) + '...';

export const capitalize = value => value ? value.toString().charAt(0).toUpperCase() + value.slice(1) : '';

export const numberFormat = number => number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");