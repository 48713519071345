import Vue from 'vue';

const getColor = {
    install(Vue, options) {
        Vue.prototype.$getColor = (status) => {
            let color = 'light-green lighten-5';

            switch (status) {

                case 'pr_check':
                    color = 'cyan darken-1';
                    break;

                case 'pr_review':
                    color = 'cyan darken-1';
                    break;

                case 'seo_review':
                    color = 'teal darken-1';
                    break;

                case 'seo_check':
                    color = 'teal darken-1';
                    break;

                case 'add_invoice':
                    color = 'cyan darken-1';
                    break;

                case 'invoice_check':
                    color = 'teal darken-1';
                    break;

                case 'final_check':
                    color = 'purple darken-1';
                    break;

                case 'partner_sign':
                    color = 'amber darken-1';
                    break;

                case 'payer_sign':
                    color = 'orange darken-1';
                    break;

                case 'template_control':
                    color = 'light-green darken-1';
                    break;

                case 'template':
                    color = 'lime darken-1';
                    break;

                case 'requisites':
                    color = 'cyan darken-1';
                    break;

                case 'win':
                    color = 'green darken-1';
                    break;

                case 'moderate':
                    color = 'primary darken-2';
                    break;

                case 'lost':
                    color = 'red darken-2';
                    break;


                case 'open':
                    color = 'red darken-2';
                    break;

                case 'blocked':
                    color = 'red darken-2';
                    break;

                case 'draft':
                    color = 'grey';
                    break;

                case 'in_progress':
                    color = 'grey';
                    break;

                case 'ready':
                    color = 'primary';
                    break;

                case 'approved':
                    color = 'purple';
                    break;

                case 'pause':
                    color = 'primary';
                    break;

                case 'go_on':
                    color = 'green darken-1';
                    break;

                case 'check':
                    color = 'primary';
                    break;

                case 'reg_check':
                    color = 'primary darken-2';
                    break;

                case 'offline_check':
                    color = 'primary';
                    break;

                case 'chief_check':
                    color = 'cyan darken-3';
                    break;

                case 'audit':
                    color = 'cyan';
                    break;

                case 'final_approve':
                    color = 'cyan darken-3';
                    break;

                case 'docs_step_1':
                    color = 'blue-grey darken-1';
                    break;

                case 'docs_step_2':
                    color = 'blue-grey darken-2';
                    break;

                case 'docs_step_3':
                    color = 'blue-grey darken-3';
                    break;

                case 'docs_step_4':
                    color = 'blue-grey darken-4';
                    break;

                case 'reg_teamlead_check':
                    color = 'primary darken-2';
                    break;

                case 'done':
                    color = 'purple';
                    break;

                case 'buying_approve':
                    color = 'indigo darken-1';
                    break;

                case 'head_approve':
                    color = 'teal darken-1';
                    break;

                case 'canceled':
                    color = 'grey darken-2';
                    break;

                case 'review':
                    color = 'indigo darken-1';
                    break;

                case 'revision':
                    color = 'indigo darken-3';
                    break;

                case 'send_approval':
                    color = 'light-blue darken-1';
                    break;

                case 'approval':
                    color = 'blue darken-2';
                    break;

                case 'active':
                    color = 'green ';
                    break;

                case 'closed':
                    color = 'primary';
                    break;

                case 'added':
                    color = 'green';
                    break;

                case 'correction':
                    color = 'grey darken-1';
                    break;

                case 'change_payment_method':
                    color = 'red darken-2';
                    break;

                case 'waiting_for_payment':
                    color = 'yellow darken-2';
                    break;

                case 'partner_confirmation':
                    color = 'yellow darken-4';
                    break;

                case 'corrected':
                    color = 'yellow darken-2';
                    break;

                case 'cancel':
                    color = 'grey';
                    break;

                case 'new':
                    color = 'green darken-2';
                    break;

                case 'sent':
                    color = 'blue darken-2';
                    break;

                case 'hold':
                    color = 'brown darken-1';
                    break;

                case 'no_reply':
                    color = 'red darken-2';
                    break;

                case 'rejected':
                    color = 'red darken-2';

                    break;

                case 'close':
                    color = 'brown darken-1';

                    break;

                case 'refused':
                    color = 'red darken-2';
                    break;

                case 'not_set':
                    color = 'red darken-2';
                    break;

                case 'negotiations':
                    color = 'cyan darken-1';
                    break;

                case 'cooperation':
                    color = 'deep-purple';
                    break;

                case 'terminated':
                    color = 'brown lighten-3';
                    break;

                case 'coop_client':
                    color = 'deep-purple darken-2';
                    break;

                case 'deleted':
                    color = 'red darken-2';
                    break;

                case 'bug':
                    color = 'red darken-2';
                    break;

                case 'client':
                    color = 'red darken-2';
                    break;

                case 'decline':
                    color = 'lime darken-1';
                    break;

                case 'non_working':
                    color = 'deep-orange';
                    break;

                case 'monitoring':
                    color = 'amber darken-4';
                    break;

                case 'traffic_drain':
                    color = 'red darken-1';
                    break;

                case 'domain_blocking':
                    color = 'pink darken-1';
                    break;

                case 'no_links':
                    color = 'teal darken-1';
                    break;

                case 'inner':
                    color = 'cyan darken-2';
                    break;

                case 'permission':
                    color = 'light-green darken-1 ';
                    break;

                case 'no_brandsteal':
                    color = 'light-blue darken-1';
                    break;

                case 'brandsteal':
                    color = 'pink darken-2';
                    break;

                default:
                    color = 'light-green lighten-5';
                    break;
            }

            return color;
        }
    }
};

Vue.use(getColor);
