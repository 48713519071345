import Vue from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import VuetifyConfirm from 'vuetify-confirm';
import * as filters from './plugins/filters';
import * as EVENTS from "./store/events";
import _ from 'lodash'
import VueSanitize from "vue-sanitize";
import Pusher from 'pusher-js'
// Plugins
import './plugins';
import * as ACTIONS from './store/actions';

import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

Object.keys(filters).forEach(name => Vue.filter(name, filters[name]));
Vue.config.productionTip = false;
Vue.use(VuetifyConfirm, {
    buttonFalseText: 'Oh, no',
    buttonTrueText: 'Yes',
    width: 400,
    color: 'warning',
    icon: 'warning',
    title: 'Warning',
});

const defaultOptions = {
    allowedTags: ['a', 'p', 'img', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'ul', 'ol', 'li', 'strong', 'iframe'],
    allowedAttributes: {
        'a': ['href', 'target', 'class'],
        'img': ['src', 'class'],
        'p': ['class'],
        'li': ['class'],
        'iframe': ['src', 'class', 'frameborder', 'allowfullscreen'],
    }
};
Vue.use(VueSanitize, defaultOptions);
var VueScrollTo = require('vue-scrollto');

// You can also pass in the default options
Vue.use(VueScrollTo);
Vue.use(_);

window.io = require('socket.io-client');
/*
Sentry.init({
    Vue,
    dsn: SENTRY_URL,
    integrations: [
        new BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracingOrigins: ["https://beta.solo-crm.com/#/", "https://solo-crm.com/#/", /^\//],
        }),
    ],
    tracesSampleRate: 0.0,
    environment: ENVIRONMENT,
    enabled: ENVIRONMENT === 'prod' || ENVIRONMENT === 'beta'
});
*/
new Vue({
    render: h => h(App),
    store,
    router,
    beforeCreate: function () {
        this.$store.dispatch(ACTIONS.GET_ICONS_LIST)
        this.$store.dispatch(ACTIONS.GET_MANUALS)
        // проверяем наличие токена при перезагрузке страницы
        this.$store.dispatch(ACTIONS.GET_PROFILE).then(res => {
            this.$subscribe(res.id);
        });
    },
}).$mount('#app');
