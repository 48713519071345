import Vue from 'vue';
import axios from 'axios';

import * as ACTIONS from '../actions';

const vue = new Vue();

const state = {

};

const getters = {

};

const actions = {

    [ACTIONS.GET_COMMENTS]: ({getters}, deal_id) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.communication.comments + '/' + deal_id)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.GET_ALL_COMMENTS]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.communication.chats + '/' + params.deal_id, {params: params})
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.SEND_COMMENT]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.communication.comments + '/' + data.deal_id, data.file, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(res => {
                    resolve(res.data.data);
                    vue.$notifySuccess({text: 'Sent'});
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.GET_NOTIFICATIONS]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.notifications.notifications, {params: params})
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.GET_NOTIFICATIONS_TRIGGER]: ({getters}) => {
        return new Promise((resolve, reject) => {
            return axios
                .get(getters.api.notifications.triggers)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                    reject(err.response);
                });
        });
    },

    [ACTIONS.READ_NOTIFICATION]: ({getters}, params) => {
        return new Promise((resolve) => {
            axios
                .post(getters.api.notifications.notificationsRead + '/' + params.notification_id)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.READ_ALL_NOTIFICATION]: ({getters}) => {
        return new Promise((resolve) => {
            axios
                .post(getters.api.notifications.all_read)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.GET_USER_NOTIFICATION_COUNT]: ({getters}) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.notifications.counters)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.CREATE_NEW_TICKET]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.communication.comments, data.file, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(res => {
                    resolve(res.data.data);
                    vue.$notifySuccess({text: 'Sent'});
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.GET_USER_CHATS]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.chat.chat, {params: params})
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.CREATE_NEW_CHAT]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.chat.createChat, data.file, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(res => {
                    resolve(res.data.data);
                    vue.$notifySuccess({text: 'Sent'});
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.DELETE_USER_CHAT]: ({getters}, data) => {
        return new Promise((resolve, reject) => {
            return axios
                .put(getters.api.chat.deleteChat, data)
                .then(res => {
                    resolve(res.data.data);
                    vue.$notifySuccess({text: 'Deleted'});
                })
                .catch(err => {
                    vue.$catchProceed(err);
                    reject(err.response);
                });
        });
    },

    [ACTIONS.READ_USER_CHAT]: ({getters}, data) => {
        return new Promise((resolve, reject) => {
            return axios
                .put(getters.api.chat.readChat, data)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                    reject(err.response);
                });
        });
    },

    [ACTIONS.UNREAD_USER_CHAT]: ({getters}, data) => {
        return new Promise((resolve, reject) => {
            return axios
                .put(getters.api.chat.unreadChat, data)
                .then(res => {
                    resolve(res.data.data);
                    vue.$notifySuccess({text: 'Updated'});
                })
                .catch(err => {
                    vue.$catchProceed(err);
                    reject(err.response);
                });
        });
    },

    [ACTIONS.GET_USER_CHAT_COUNTER]: ({getters}) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.chat.unreadChatsCounter)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.GET_MESSAGES_BY_CHAT_ID]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.chat.chatMessages + '/' + params.chat_id)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.SEND_MESSAGE_TO_CHAT]: ({getters}, data) => {
        return new Promise((resolve, reject) => {
            return axios
                .post(getters.api.chat.sendMessage + '/' + data.chat_id, data.file, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(res => {
                    resolve(res.data.data);
                    vue.$notifySuccess({text: 'Sent'});
                })
                .catch(err => {
                    vue.$catchProceed(err);
                    reject(err.response);
                });
        });
    },


};

const mutations = {

};

export default {
    state,
    getters,
    actions,
    mutations,
}
