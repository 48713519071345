import Vue from 'vue';
import axios from 'axios';
import * as ACTIONS from '../actions';

const vue = new Vue();

const state = {
    payments: {
        loader: true,
    },
    added_payments: [],
    payments_cost: 0,
};

const getters = {
    paymentsLoader: state => state.payments.loader,
    added_payments: state => state.added_payments,
    payments_cost: state => state.payments_cost,
};

const actions = {
    [ACTIONS.CREATE_NEW_PAYMENT]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .put(getters.api.payments.payments, data)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.GET_PAYMENT_LIST_BY_DEAL_ID]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.payments.byDealId + '/' + params.deal_id, {params: params})
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.GET_PAYMENT_INFO]: ({getters}, paymentId) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.payments.payments + '/' + paymentId)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.UPDATE_PAYMENT_STATUS]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.payments.updateStatus + '/' + data.payment_id, data)
                .then(res => {
                    vue.$notifySuccess({text: 'Payment update'});
                    resolve(res.data);
                })
                .catch(error => {
                    vue.$catchProceed(error);
                });
        });
    },
    [ACTIONS.UPDATE_PAYMENT_STATUSES]: ({getters}, data) => {
        return new Promise((resolve, reject) => {
            return axios
                .post(getters.api.payments.updateStatuses + '/' + data.status, data)
                .then(res => {
                    vue.$notifySuccess({text: 'Payments update'});
                    resolve(res.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                    reject(err.response);
                });
        });
    },
    [ACTIONS.UPDATE_PAYMENT]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.payments.payments + '/' + data.id, data)
                .then(res => {
                    vue.$notifySuccess({text: 'Payment update'});
                    resolve(res.data);
                })

                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.PAYMENTS_LIST]: ({commit, getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.payments.payments, {params: params})
                .then(res => {
                    commit('setPaymentsLoader', false);
                    resolve(res.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    // start actions for payment file
    [ACTIONS.PAYMENT_UPLOAD_FILE]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.payments.uploadDealsFile + '/' + data.payment_id, data.file, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(res => {
                    vue.$notifySuccess({text: 'File uploaded'});
                    resolve(res.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.PAYMENT_DELETE_FILE]: ({getters}, payment_file_id) => {
        return new Promise((resolve) => {
            axios
                .delete(getters.api.payments.deleteDealsFile + '/' + payment_file_id)
                .then(res => {
                    vue.$notifySuccess({text: 'File deleted'});
                    resolve(res);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.PAYMENT_FILE_DOWNLOAD]: ({getters}, data) => {
        return new Promise(() => {
            return axios
                .get(getters.api.payments.getFileHash + '/' + data.file_id)
                .then(res => {
                    window.location.href = process.env.VUE_APP_API_URL + res.data.data.link;
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    // end actions for payment file

    // start actions for payment invoice
    [ACTIONS.PAYMENTS_TO_INVOICES]: ({getters}, data) => {
        return new Promise((resolve, reject) => {
            return axios
                .put(getters.api.invoices.invoices, data)
                .then(res => {
                    resolve(res.data.data);
                    vue.$notifySuccess({text: 'Payments added to invoice'});
                })
                .catch(err => {
                    vue.$catchProceed(err);
                    reject(err.response);
                });
        });
    },
    [ACTIONS.PAYMENT_INVOICE_DOWNLOAD]: ({getters}, data) => {
        return new Promise(() => {
            return axios
                .get(getters.api.invoices.invoices + '/' + data.invoice_id)
                .then(res => {
                    window.location.href = process.env.VUE_APP_API_URL + res.data.data.link;
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.PAYMENT_INVOICES_LIST]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.invoices.invoices, {params: params})
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                    vue.$notifyError({text: 'Ivoices list info error'});
                });
        });
    },
    [ACTIONS.PAYMENT_INVOICE_COUNT]: ({commit, getters}, params) => {
        // есди это поиск по кошельку или сделке - будет только одна страница результатов
        if (params.deal_id !== null || params.wallet !== null || params.payment_id !== null) {
            commit('setInvoiceCount', 1);
        } else {
            axios
                .get(getters.api.invoices.count, {params: params})
                .then(res => {
                    commit('setInvoiceCount', res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        }

    },
    // end actions for deal invoice

    [ACTIONS.PAYMENT_CHANGE_PAYMENT_METHOD]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.invoices.paymentChangePaymentMethod + '/' + data.payment_id, data)
                .then(res => {
                    vue.$notifySuccess({text: 'Invoice update'});
                    resolve(res.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.PAYMENT_TARGETS_LIST]: ({getters}) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.paymentTargets.paymentTargets)
                .then(res => {
                    resolve(res.data.data);
                })
        });
    },
    [ACTIONS.PAYMENT_TARGETS_BY_MANAGER]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.paymentTargets.paymentTargetsByManager + '/' + params.product_id)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.CREATE_NEW_ADJUSTMENTS]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .put(getters.api.deals.adjustment + '/' + data.deal_id, data)
                .then(res => {
                    vue.$notifySuccess({text: 'Adjustment create'});
                    resolve(res.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.ADJUSTMENTS_LIST]: ({getters}, deal_id) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.deals.adjustment + '/' + deal_id)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.TO_BLACK_LIST]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .put(getters.api.blacklists.blacklists, data)
                .then(res => {
                    vue.$notifySuccess({text: res.data.data.message});
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.GET_BLACK_LIST]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.blacklists.blacklists, {params: params})
                // .get(getters.api.blacklists.blacklists)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.DELETE_FROM_BLACK_LIST]: ({getters}, blacklist_id) => {
        return new Promise((resolve) => {
            axios
                .delete(getters.api.blacklists.blacklists + '/' + blacklist_id)
                .then(res => {
                    vue.$notifySuccess({text: 'Deleted'});
                    resolve(res);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.UPDATE_PAYMENT_COST]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.payments.updateCost + '/' + data.payment_id, data)
                .then(res => {
                    vue.$notifySuccess({text: 'Payment update'});
                    resolve(res.data);
                })

                .catch(error => {
                    vue.$catchProceed(error);
                });
        });
    },
    [ACTIONS.GET_PAYMENT_STATUSES]: ({getters}) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.payments.statuses)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.RETURN_PAYMENT_TO_AUDIT]: ({getters}, payment_id) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.payments.forceAudit + '/' + payment_id)
                .then(res => {
                    vue.$notifySuccess({text: 'Payment update'});
                    resolve(res.data);
                })

                .catch(error => {
                    vue.$catchProceed(error);
                });
        });
    },

    [ACTIONS.GET_STATS_FOR_COMMENT]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.payments.comment + '/' + params.deal_id, {params: params})
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.GENERATE_STATS_COMMENT]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .put(getters.api.payments.comment + '/' + data.deal_id, data)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.EDIT_PAYMENT_COMMENT]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.payments.updateStatus + '/' + data.payment_id, data)
                .then(res => {
                    vue.$notifySuccess({text: 'Comment updated'});
                    resolve(res.data);
                })
                .catch(error => {
                    vue.$catchProceed(error);
                });
        });
    },

    [ACTIONS.LOCK_PAYMENT]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.payments.auditor_lock, data)
                .then(res => {
                    vue.$notifySuccess({text: res.data.data.message});
                    resolve(res.data);
                })
                .catch(error => {
                    vue.$catchProceed(error);
                });
        });
    },

    [ACTIONS.EXCHANGE_PAYMENT_COST]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.payments.exchange, {params: params})
                .then(res => {

                    resolve(res.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.SET_DEAL_PAYMENT]: ({commit}, deal_payment) => {
        commit('addDealPayment', deal_payment);
    },
    [ACTIONS.EDIT_DEAL_PAYMENT]: ({commit}, data ) => {
        commit('editDealPayment', data);
    },
    [ACTIONS.DELETE_DEAL_PAYMENT]: ({commit}, index) => {
        commit('deleteDealPayment', index);
    },
    [ACTIONS.CLEAR_PAYMENTS_STORE]: ({commit}) => {
        commit('clearPayments');
    },
    [ACTIONS.PAYMENT_COST]: ({commit}, cost) => {
        commit('setPaymentCost', cost);
    },
    [ACTIONS.CLEAR_PAYMENT_COST]: ({commit}) => {
        commit('clearPaymentCost');
    },
};

const mutations = {
    setPaymentsLoader(state, payload) {
        state.payments.loader = payload;
    },
    addDealPayment(state, payload) {
        state.added_payments.push(payload);
    },
    editDealPayment(state, payload) {
        Object.assign(state.added_payments[payload.index], payload.payment);
    },
    deleteDealPayment(state, payload) {
        state.added_payments.splice(payload, 1);
    },
    clearPayments(state) {
        state.added_payments = [];
        state.payments_cost = 0;
    },
    clearPaymentCost(state) {
        state.payments_cost = 0;
    },
    setPaymentCost(state, payload) {
        state.payments_cost = payload;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
}
