import Vue from 'vue';
import axios from 'axios';

import * as ACTIONS from '../actions';

const vue = new Vue();

const state = {

};

const getters = {

};

const actions = {

    [ACTIONS.GET_TICKETS]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.tickets.tickets, {params: params})
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.GET_COMPARE_TICKETS]: ({getters}) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.tickets.compare)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.GET_TICKETS_COUNT]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.tickets.count, {params: params})
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.UPDATE_TICKET]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.tickets.tickets + '/' + data.id, data)
                .then(res => {
                    vue.$notifySuccess({text: 'Ticket update'});
                    resolve(res.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },


    [ACTIONS.GET_TICKETS_STAT]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.tickets.dash, {params: params})
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

};

const mutations = {

};

export default {
    state,
    getters,
    actions,
    mutations,
}