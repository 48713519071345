import axios from 'axios';
import * as ACTIONS from '../actions';
import Vue from 'vue';

const vue = new Vue();

const state = {
    addedPartnerContacts: [],
    validationContact: false
};

const getters = {
    addedPartnerContacts: state => state.addedPartnerContacts,
    validationContact: state => state.validationContact,
};

const actions = {

    [ACTIONS.PARTNER_CONTACTS_CREATE]: ({getters}, params) => {
        return new Promise((resolve, reject) => {
            // params.contact
            return axios
                .put(getters.api.partners.contacts + '/' + params.partner_id, params)
                .then(() => {
                    resolve(true);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                    reject(false);
                });
        });
    },

    [ACTIONS.PARTNER_CONTACT_GET]: ({getters}, partner_contact_id) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.partners.contacts + '/' + partner_contact_id)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.PARTNER_CONTACTS_EDIT]: ({getters}, data) => {
        return new Promise((resolve, reject) => {
            return axios
                .post(getters.api.partners.contacts + '/' + data.contact_id, data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                    reject(err);
                });
        });
    },

    [ACTIONS.PARTNER_CONTACT_VALIDATION]: ({commit}, data) => {
        return new Promise((resolve) => {
            commit('validationContact', data);
            resolve();
        });
    },

    [ACTIONS.PARTNER_CONTACT_DELETE]: ({getters}, partner_contact_id) => {
        return new Promise((resolve) => {
            axios
                .delete(getters.api.partners.contacts + '/' + partner_contact_id)
                .then(res => {
                    vue.$notifySuccess({text: 'Contact deleted'});
                    resolve(res);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

};

const mutations = {
    addedPartnerContacts(state, payload) {
        state.addedPartnerContacts[payload.index] = payload.new_tab;
    },
    deletePartnerContact(state, payload) {
        state.addedPartnerContacts[payload] = undefined;
    },
    validationContact(state, payload) {
        state.validationContact = payload;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
}