import * as ACTIONS from '../actions';
import axios from 'axios';
import Vue from 'vue';
import router from '../../router';

const vue = new Vue();

const state = {
    groupList: []
};

const getters = {
    partnersGroupList: state => state.groupList,
};

const actions = {
    [ACTIONS.PARTNER_GROUP_LIST]: ({commit, getters}) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.partners.groups)
                .then(res => {
                    commit('setPartnerGroupList', res.data.data);
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.PARTNER_GROUP_CREATE]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .put(getters.api.partners.groups, data)
                .then(res => {
                    vue.$notifySuccess({text: 'Group created'});
                    resolve(res.data.data);
                    router.push('/partners/groups');
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.PARTNER_GROUP_SEARCH_SITE]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.partners.searchSite + '/' + params.param_value)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.PARTNER_GROUP_SEARCH_PARTNER]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.partners.search + '/' + params.param_value)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.PARTNER_GROUP_GET_INFO]: ({getters}, partner_id) => {

        return new Promise((resolve) => {
            return axios
                .get(getters.api.partners.groups + '/' + partner_id)
                .then(res => {
                    resolve(res.data.data);

                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });

    },

    [ACTIONS.PARTNER_GROUP_EDIT]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.partners.groups + '/' + data.group_id, data)
                .then(res => {
                    vue.$notifySuccess({text: 'Group update'});
                    resolve(res);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

};

const mutations = {
    setPartnerGroupList(state, payload) {
        state.groupList = payload
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
}
