import Vue from 'vue';
import axios from 'axios';
import * as ACTIONS from '../actions';

const vue = new Vue();

const state = {

};

const getters = {

};

const actions = {

    [ACTIONS.UPDATE_USER_REMINDERS]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .put(getters.api.reminders.reminders + '/' + data.reminder_id, data)
                .then(res => {
                    vue.$notifySuccess({text: 'Reminder updated'});
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.CREATE_USER_REMINDERS]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.reminders.reminders, data)
                .then(res => {
                    vue.$notifySuccess({text: 'Reminder created'});
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.DELETE_USER_REMINDERS]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .delete(getters.api.reminders.reminders + '/' + data.reminder_id)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.GET_USER_REMINDERS]: ({getters}) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.reminders.reminders)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
};

const mutations = {

};

export default {
    state,
    getters,
    actions,
    mutations,
}
