import Vue from 'vue';
import axios from 'axios';

import * as ACTIONS from '../actions';

const vue = new Vue();

const state = {
    icons: new Map()
};

const getters = {
    iconsByLabel: state => state.icons,
};

const actions = {
    [ACTIONS.GET_ICONS_LIST]: ({commit, getters}) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.icontitles.icontitles)
                .then(res => {
                    commit('setIconStore', res.data.data);
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.TO_ICON_LIST]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .put(getters.api.icontitles.icontitles, data)
                .then(res => {
                    resolve(res.data.data);
                    vue.$notifySuccess({text: 'Added to icon list'});
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.DELETE_FROM_ICON_LIST]: ({getters}, icon_id) => {
        return new Promise((resolve) => {
            axios
                .delete(getters.api.icontitles.icontitles + '/' + icon_id)
                .then(res => {
                    vue.$notifySuccess({text: 'Deleted'});
                    resolve(res);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
};

const mutations = {
    setIconStore(state, payload) {
        state.icons = [];
        let iconsByLabel = new Map();

        for (let i = 0; i < payload.length; i++) {
            iconsByLabel.set(payload[i].title, payload[i].icon);
        }
        state.icons = iconsByLabel;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
}