import Vue from 'vue';
import VueClipboard from 'vue-clipboard2';
Vue.use(VueClipboard);

const vue = new Vue();

const Copy = {
    install(Vue, options) {
        Vue.prototype.$copy = (message) => {
            vue.$copyText(message).then(function (e) {
                vue.$notifySuccess({text: 'Copied to clipboard'});
            });
        }
    }
};

Vue.use(Copy);