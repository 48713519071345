import Vue from 'vue';
import axios from 'axios';
import * as ACTIONS from '../actions';
const vue = new Vue();

const actions = {

    [ACTIONS.GET_AFFILIATE_REPORT]: ({getters}, params) => {
        return new Promise((resolve, reject) => {
            return axios
                .get(getters.api.partners.affiliates, {params: params})
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                    reject(err.response);
                });
        });
    },


    [ACTIONS.DOWNLOAD_AFFILIATE_STATS]: ({getters}, params) => {
        return axios
            .get(getters.api.partners.xls, {params})
            .then(() => {
                vue.$notifyInfo({text: 'The link will be sent to you in notifications'});
            })
            .catch(err => {
                vue.$catchProceed(err);
            });
    },
};

export default {
    actions,
}
