import Vue from 'vue';

import moment from 'moment';
// Monday is the first day of the week.
moment.updateLocale('en', {week: {dow: 1}});


const vue = new Vue();

const Ranges = {
    install(Vue, options) {
        Vue.prototype.$ranges = () => {
            return {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'This week': [moment().startOf('week'), moment().endOf('week')],
                'This month': [moment().startOf('month'), moment().endOf('month')],
                'Last week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
                'Last month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                'This year': [moment().startOf('year'), moment().endOf('year')],
            };
        }
    }
};

Vue.use(Ranges);