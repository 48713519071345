import Vue from 'vue';
import axios from 'axios';
import * as ACTIONS from '../actions';

const vue = new Vue();

const state = {

};

const getters = {

};

const actions = {

    [ACTIONS.CREATE_NEW_ZID]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .put(getters.api.zid.zid + '/' + data.deal_id, data)
                .then(res => {
                    vue.$notifySuccess({text: 'Zid created'});
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.GET_ZID_LIST_BY_DEAL_ID]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.zid.byDealId + '/' + params.deal_id, {params: params})
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },


    [ACTIONS.GET_ZID_LIST_BY_SITE_ID]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.zid.zidPlaces + '/' + params.deal_id + '/' + params.site_id, {params: params})
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.ZID_COUNT]: ({getters}, params) => {

        return new Promise((resolve) => {
            return axios
                .get(getters.api.zid.count + '/'+ params.deal_id, {params: params})
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.GET_ZID_INFO]: ({getters}, zid_id) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.zid.zid + '/' + zid_id)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.UPDATE_ZID]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.zid.zid + '/' + data.id, data)
                .then(res => {
                    vue.$notifySuccess({text: 'Zid update'});
                    resolve(res.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.DELETE_ZID]: ({getters}, id) => {
        return new Promise((resolve) => {
            axios
                .delete(getters.api.zid.zid + '/' + id)
                .then(res => {
                    vue.$notifySuccess({text: 'Zid deleted'});
                    resolve(res);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
};

const mutations = {

};

export default {
    state,
    getters,
    actions,
    mutations,
}