import Vue from 'vue';
import axios from 'axios';
import * as ACTIONS from '../actions';

const vue = new Vue();

const state = {

};

const getters = {

};

const actions = {

    [ACTIONS.GET_PROJECTS_LIST]: ({getters}) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.seo.projects)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.GET_PROJECT_DATA]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.seo.projects + '/' + params.project_id)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.GET_KEY_DATA]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.seo.competitors + '/' + params.project_id, {params})
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.GET_PROJECT_STATS]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.seo.stat + '/' + params.project_id, {params})
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.GET_PROJECT_POSITION]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.seo.position + '/' + params.project_id, {params})
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },


    [ACTIONS.GET_PROJECTS_LIST_BY_USER]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.seo.projectsByUser + '/' + params.user_id, {params})
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.DOWNLOAD_SEO_XLS]: ({getters}, data) => {
        return axios
            .get(getters.api.seo.xls, {params: data})
            .then(() => {
                vue.$notifyInfo({text: 'The link will be sent to you in notifications'});
            })
            .catch(err => {
                vue.$catchProceed(err);
            });
    },

    [ACTIONS.DOWNLOAD_SEO_COMPETITORS_XLS]: ({getters}, params) => {
        return axios
            .get(getters.api.seo.competitors_xls, {params})
            .then(() => {
                vue.$notifyInfo({text: 'The link will be sent to you in notifications'});
            })
            .catch(err => {
                vue.$catchProceed(err);
            });
    },

};

const mutations = {};

export default {
    state,
    getters,
    actions,
    mutations,
}
