import Vue from 'vue';
const vue = new Vue();
import store from '../store';

const InputIcon = {
    install(Vue, options) {
        Vue.prototype.inputIcon = (label) => {
            return store.getters.iconsByLabel.get(label) || 'warning'
        }
    },
}
Vue.use(InputIcon)
