import axios from 'axios';
import * as ACTIONS from '../actions';
import Vue from 'vue';

const vue = new Vue();

const state = {


};

const getters = {

};

const actions = {
    [ACTIONS.GET_SMM_TITLES]: ({getters}) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.deals.titles)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.UPDATE_SMM_TITLES]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.deals.titles + '/' + data.site_overview_id, data)
                .then(res => {
                    vue.$notifySuccess({text: 'Title update'});
                    resolve(res.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
};

const mutations = {

};

export default {
    state,
    getters,
    actions,
    mutations
}