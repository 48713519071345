import Vue from 'vue';
import axios from 'axios';
import * as ACTIONS from '../actions';

const vue = new Vue();

const state = {
    list: {
        countryList: [],
        geoGroupList: [],
        sourceTypeList: [],
        sourceCategoryList: [],
        roleList: [],
        managerList: [],
        logManagerList: [],
        statusList: [],
        statusChangeList: [],
    },
    addedFilters: [],
};

const getters = {
    countryList: state => state.list.countryList,
    sourceTypeList: state => state.list.sourceTypeList,
    sourceCategoryList: state => state.list.sourceCategoryList,
    roleList: state => state.list.roleList,
    leadStatusList: state => state.list.statusList,
    leadStatusChangeList: state => state.list.statusChangeList,
    leadManagerList: state => state.list.managerList,
    logManagerList: state => state.list.logManagerList,
    addedFilters: state => state.addedFilters,
};

const actions = {
    [ACTIONS.USER_FILTER_ADD]: ({commit}, filter) => {
        commit('addUserFilter', filter);
    },
    [ACTIONS.USER_FILTER_CLEAR]: ({commit}, filter) => {
        commit('clearUserFilter', filter);
    },
    [ACTIONS.USER_FILTER_DELETE]: ({commit}, index) => {
        commit('deleteUserFilter', index);
    },
    [ACTIONS.USER_FILTER_EDIT]: ({commit}, data) => {
        commit('editUserFilter', data);
    },
    [ACTIONS.ROLES_LIST]: ({commit, getters, dispatch}) => {
        return new Promise((resolve) => {
            axios
                .get(getters.api.filters.getRoleList)
                .then(res => {
                    commit('setRoleList', res.data.data);
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                    dispatch(ACTIONS.LOGOUT);
                });
        });
    },

    [ACTIONS.GET_USER_ROLES]: ({getters}) => {
        return new Promise((resolve) => {
            axios
                .get(getters.api.filters.userRoles)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.COUNTRIES_LIST]: ({commit, getters, dispatch}) => {
        return new Promise((resolve) => {
            axios
                .get(getters.api.filters.getCountryList)
                .then(res => {
                    commit('setCountryList', res.data.data);
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                    dispatch(ACTIONS.LOGOUT);
                });
        });
    },
    [ACTIONS.SOURCE_TYPES_LIST]: ({commit, getters, dispatch}, params) => {
        return new Promise((resolve) => {
            axios
                .get(getters.api.filters.getSourceTypeList, {params: params})
                .then(res => {
                    commit('setSourceTypeList', res.data.data);
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                    dispatch(ACTIONS.LOGOUT);
                });
        });
    },
    [ACTIONS.SOURCE_CATEGORIES_LIST]: ({commit, getters, dispatch}) => {
        return new Promise((resolve) => {
            axios
                .get(getters.api.filters.getSourceCategoryList)
                .then(res => {
                    commit('setSourceCategoryList', res.data.data);
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                    dispatch(ACTIONS.LOGOUT);
                });
        });
    },
    [ACTIONS.LEAD_STATUS_LIST]: ({commit, getters}) => {

        return axios
            .get(getters.api.filters.getStatusList)
            .then(res => {
                commit('setLeadStatusList', res.data.data);
            })
            .catch(err => {
                vue.$catchProceed(err);
            });
    },

    [ACTIONS.LEAD_STATUS_CHANGE_LIST]: ({commit, getters}) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.filters.getStatusChangeList)
                .then(res => {
                    commit('setLeadStatusChangeList', res.data.data);
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.LEAD_MANAGER_LIST]: ({commit, getters}) => {

        return new Promise((resolve) => {
            axios
                .get(getters.api.filters.getManagerList)
                .then(res => {
                    commit('setLeadManagerList', res.data.data);
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.TEAM_MANAGER_LIST]: ({getters}) => {
        return new Promise((resolve) => {
            axios
                .get(getters.api.filters.teamManagerList)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.LOG_MANAGER_LIST]: ({commit, getters}) => {

        return axios
            .get(getters.api.filters.getLogManagerList)
            .then(res => {
                commit('setLogManagerList', res.data.data);
            })
            .catch(err => {
                vue.$catchProceed(err);
            });
    },

    [ACTIONS.DEALS_TYPES]: ({getters}) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.filters.dealTypes)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.COUNTRIES_LIST_BY_USER]: ({getters}) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.filters.userCountriesAccess)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
};

const mutations = {
    clearUserFilter(state) {
        state.addedFilters = [];
    },
    addUserFilter(state, payload) {
        state.addedFilters.push(payload);
    },
    deleteUserFilter(state, payload) {
        state.addedFilters.splice(payload, 1);
    },
    editUserFilter(state, payload) {
        state.addedFilters[payload.index] = payload.new_value;
    },

    setRoleList(state, payload) {
        state.list.roleList = payload;
    },
    setCountryList(state, payload) {
        state.list.countryList = payload;
    },
    setGeoGroupList(state, payload) {
        state.list.geoGroupList = payload;
    },
    setSourceTypeList(state, payload) {
        state.list.sourceTypeList = payload;
    },
    setSourceCategoryList(state, payload) {
        state.list.sourceCategoryList = payload;
    },
    setLeadStatusList(state, payload) {
        state.list.statusList = payload;
    },
    setLeadStatusChangeList(state, payload) {
        state.list.statusChangeList = payload;
    },
    setLeadManagerList(state, payload) {
        state.list.managerList = payload;
    },
    setLogManagerList(state, payload) {
        state.list.logManagerList = payload;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
}