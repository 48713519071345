import Vue from 'vue';
import axios from 'axios';
import * as ACTIONS from '../actions';
const vue = new Vue();

const actions = {
    [ACTIONS.GET_AFFILIATE_TICKETS_LIST]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.affiliate.tickets, {params: params})
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.GET_AFFILIATE_TICKET_CARD]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.affiliate.tickets + '/' + params.ticket_id)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.GET_PROBLEMS_LIST]: ({getters}) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.affiliate.problems)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.GET_TRIGGERS_LIST]: ({getters}) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.affiliate.triggers)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.ADD_PROBLEM_TO_TICKET]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.affiliate.tickets + '/' + data.ticket_id, data)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.CREATE_AFFILIATE_TICKET]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.affiliate.tickets, data)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.GET_AFFILIATE_MANAGERS]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.affiliate.affiliate + '/' + data.affiliate_id + '/' + 'managers')
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.ADD_MANAGER_TO_PARTNER]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.affiliate.affiliate + '/' + data.affiliate_id + '/' + 'managers', data)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.CHOOSE_MAIN_MANAGER]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.affiliate.affiliate + '/' + data.affiliate_id + '/' + 'managers/main', data)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.DELETE_AFFILIATE_MANAGERS]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .delete(getters.api.affiliate.affiliate + '/' + params.affiliate_id + '/' + 'managers', {params:params})
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.DELETE_TICKET_PROBLEM]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .delete(getters.api.affiliate.tickets + '/' + params.ticket_id + '/' + 'problems' + '/' + params.ticket_problem_id)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.SAVE_TICKET_COMMENT]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.affiliate.tickets + '/' + data.ticket_id + '/' + 'comment', data.images, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.SAVE_PROBLEM_COMMENT]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.affiliate.tickets + '/' + data.ticket_id + '/' + 'comment' + '/' + data.ticket_problem_id, data.images, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.UPDATE_TICKET_PROBLEM]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .put(getters.api.affiliate.tickets + '/' + data.ticket_id + '/' + 'problems' + '/' + data.ticket_problem_id, data)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.GET_AFFILIATE_LIST]: ({getters}) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.affiliate.list)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

};

export default {
    actions,
}
